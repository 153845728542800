import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e80450e4 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _ff9fef36 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _06dc0dae = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _7ecea735 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _7d21aaa4 = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _5d8403f4 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _f01c7eda = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _ecb341b2 = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _140a47c0 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _64612002 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _2cfab908 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _051fccfc = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _baf518b2 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _cc8dd57e = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _f6255676 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _6643f064 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _2337e9e8 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _7738186b = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _35b889c3 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _7d7d3b04 = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _3152cf72 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _aa4bf946 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _fb0bf4b0 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _78628b18 = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _73df5f4a = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _40ed513c = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _420e885d = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _5a7ba021 = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _1f8b00e8 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _5f2bef08 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _40dcd01b = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _15a79614 = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _3bf276d4 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _296102b0 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _70e45288 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _109f3c1d = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _386541fc = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _2052bdcb = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _1c6d46b2 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _77ba4e6a = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _3edd938a = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _59cf98b5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _e80450e4,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _ff9fef36,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _06dc0dae,
    name: "help"
  }, {
    path: "/verify-email",
    component: _7ecea735,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _7d21aaa4,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _5d8403f4,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _f01c7eda,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _ecb341b2,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _140a47c0,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _64612002,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _2cfab908,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _051fccfc,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _baf518b2,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _cc8dd57e,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _f6255676,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _6643f064,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _2337e9e8,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _7738186b,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _35b889c3,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _7d7d3b04,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _3152cf72,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _aa4bf946,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _fb0bf4b0,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _78628b18,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _73df5f4a,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _40ed513c,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _420e885d,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _5a7ba021,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _1f8b00e8,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _5f2bef08,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _40dcd01b,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _15a79614,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _3bf276d4,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _296102b0,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _70e45288,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _109f3c1d,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _386541fc,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _2052bdcb,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _1c6d46b2,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _77ba4e6a,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _3edd938a,
    name: "blog-slug"
  }, {
    path: "/",
    component: _59cf98b5,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
